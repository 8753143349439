@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Francois+One&family=Nunito:wght@400;700&family=Open+Sans&family=Oswald:wght@400;700&family=Roboto&family=Yeseva+One&display=swap');

//VARIABLES
//______________________________________________

$geoCapsBold: ninoBold, sans-serif;
$geoCapsRegular: ninoRegular, sans-serif;
$geoLowerRegular: bpgArial, sans-serif;

$enBold: 'Oswald', sans-serif;
$enRegular: 'Nunito', sans-serif;

$textSecondary: #2d3748;
$textPrimary: #1a202c;

$textPrimaryWhite: #fff;
$textSecondaryWhite: rgba(255, 255, 255, 0.9);
$textLightWhite: rgba(255, 255, 255, 0.75);

$mediumGrey: #9e9e9e;
$lightGrey: #eeeeee;

$mediumAmber: #ffe082;

$break-xs: 360px;
$break-small: 768px;
$break-medium: 959px;
$break-large: 1023px;
$break-xl: 1279px;

//BASICS
//______________________________________________

input[type='text'],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

button {
    cursor: pointer;
}

hr {
    margin: 0;
    padding: 0;
    height: 1px;
    line-height: 0;
    border: none;
    background: $mediumGrey;
}

//FONTS
//______________________________________________

@font-face {
    font-family: ninoRegular;
    src: url(./assets/fonts/bpg_nino_mtavruli_normal.ttf);
}

@font-face {
    font-family: ninoBold;
    src: url(./assets/fonts/bpg_nino_mtavruli_bold.ttf);
}

@font-face {
    font-family: bpgArial;
    src: url(./assets/fonts/bpg_arial_2009.ttf);
}

//UTILS
//______________________________________________

.gw-container-lg {
    max-width: 1504px;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
}

.gw-container-md {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
}

.gw-container-xs {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
}

.gw-section-lg {
    padding: 96px 0px;
    overflow: hidden;
}

.gw-section-md {
    padding: 64px 0px;
    overflow: hidden;
}

.gw-section-xs {
    padding: 32px 0px;
    overflow: hidden;
}

//NAVBAR
//______________________________________________

.gw-navbar-wrapper {
    padding: 1.5rem 0;
    border-bottom: 1px solid $mediumGrey;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
    transition: top 0.25s ease-in;
    height: 85px;
    display: flex;
    align-items: center;
}

.gw-navbar {
    display: flex;
    align-items: center;
}

.gw-navbar-items {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: auto;

    @media only screen and (max-width: $break-large) {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &.active {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s;
        }
    }
}

.gw-navbar-item {
    display: inline-block;
    font-family: $geoCapsRegular;
    font-size: 18px;
    color: $textPrimary;

    a {
        text-decoration: none;
        color: inherit;

        &:visited {
            color: inherit;
        }
    }

    &:not(:last-child) {
        margin-right: 2.5rem;
    }

    @media only screen and (max-width: $break-large) {
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 2rem;
        }

        display: block;
        color: $textPrimaryWhite;
    }
}

.gw-navbar-logo {
    width: 250px;
    height: auto;
    z-index: 20;

    @media only screen and (max-width: $break-large) {
        width: 200px;
    }
}

.gw-menu-btn {
    margin-left: auto;
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    width: 32px;
    z-index: 20;

    @media only screen and (max-width: $break-large) {
        display: flex;
    }
}

.gw-menu-btn-burger {
    width: 24px;
    height: 2px;
    background: $textPrimary;
    transition: all 0.3s;
}

.gw-menu-btn-burger::before {
    position: absolute;
    content: '';
    width: 24px;
    height: 2px;
    background: $textPrimary;
    transform: translateY(8px);
}

.gw-menu-btn-burger::after {
    position: absolute;
    content: '';
    width: 24px;
    height: 2px;
    background: $textPrimary;
    transform: translateY(-8px);
}

.gw-menu-btn-burger.active {
    background: transparent;
    transition: all 0s;

    &::after {
        background: #fff;
        transform: rotate(-45deg);
        transition: all 0.3s;
    }

    &::before {
        background: #fff;
        transform: rotate(45deg);
        transition: all 0.3s;
    }
}

.gw-navbar-margin {
    margin-bottom: 85px;
}

.gw-navbar-language-select {
    padding: 2px;
}

//TYPOGRAPHY
//______________________________________________

h1 {
    font-size: 4rem;
}

h3 {
    font-size: 2rem;
    text-transform: uppercase;
}

h5 {
    font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    line-height: 1.618;

    &.primary {
        color: $textPrimary;
    }

    &.secondary {
        color: $textSecondary;
    }

    &.white {
        &.primary {
            color: $textPrimaryWhite;
        }

        &.secondary {
            color: $textSecondaryWhite;
        }
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $geoCapsBold;
    color: $textPrimary;
}

h6 {
    font-family: $geoCapsRegular;
    color: $textPrimary;
    font-size: 1rem;
    font-weight: 400;
}

p {
    font-family: $geoLowerRegular;
    color: $textSecondary;
}

@media only screen and (max-width: $break-small) {
    h1 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }
}

//ANIMATIONS
//______________________________________________

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-10px);
    }
    100% {
        transform: translatey(0px);
    }
}

//BUTTONS
//______________________________________________

.gw-button {
    font-size: 18px;
    color: #fff;
    font-family: $geoCapsRegular;
    padding: 1rem 1.5rem 0.8rem 1.5rem;
    background: #000;
    text-decoration: none;
    display: inline-block;
    transition: all 0.1s ease-in;
    text-transform: uppercase;
    outline: none;
    border: none;

    @media (hover: hover) {
        &:hover {
            background: #ff8f00;
        }
    }

    &.secondary {
        background: #fff;
        border: 1px solid $mediumGrey;
        color: $textPrimary;

        @media (hover: hover) {
            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }

    &.wide {
        padding: 1rem 4rem 0.8rem 4rem;
    }
}

//MAINSCREEN
//______________________________________________

.gw-main-screen-wrapper {
    height: 99vh;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -85px;
    padding: 96px 0px;
}

.gw-scroll-tip {
    position: absolute;
    height: 64px;
    bottom: 32px;
    animation: float 3s ease-in-out infinite;
    opacity: 1;
    transition: all ease-in 0.5s;

    &.hidden {
        opacity: 0;
    }

    @media only screen and (max-width: $break-large) {
        display: none;
    }
}

.gw-cta-description {
    margin-bottom: 2rem;

    @media only screen and (max-width: $break-small) {
        margin-bottom: 1rem;
    }
}

.gw-cta-header {
    margin-bottom: 1rem;
}

//BRANDSHOWCASE
//______________________________________________

.gw-brand-description {
    @media only screen and (max-width: $break-medium) {
        margin-bottom: 32px;
    }
}

.gw-brand-showcase-wrapper {
    border-bottom: 1px solid $mediumGrey;
}

.gw-brand-header {
    display: flex;
    align-items: flex-end;
}

.gw-brand-logo {
    height: 160px;
    margin-left: auto;

    @media only screen and (max-width: $break-medium) {
        height: 80px;
        padding-left: 32px;
    }
}

.gw-brand-name {
    line-height: 0.5;
}

.gw-brand-showcase {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media only screen and (max-width: $break-medium) {
        padding-bottom: 32px;
    }
}

.gw-brand-wine-description {
    margin-bottom: 16px;
}

.gw-brand-navigation-wrapper {
    display: flex;
}

.gw-brand-navigation {
    &.left {
        margin-right: auto;

        @media only screen and (max-width: $break-medium) {
            margin-right: 0;
            margin-left: auto;
        }
    }

    &.right {
        margin-left: auto;
    }
}

.gw-brand-wine-image-wrapper {
    position: relative;
    border: 1px solid $mediumGrey;
    height: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    @media only screen and (max-width: $break-medium) {
        height: 320px;
    }
}

.gw-brand-wine-image {
    display: block;
    height: 100%;
    width: auto;
}

.gw-brand-wine-info {
    @media only screen and (max-width: $break-medium) {
        margin-bottom: 32px;
    }
}

.gw-brand-hr {
    overflow: hidden;
    margin: 24px 0;
}

.gw-brand-wines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.gw-brand-wine-image-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 100%;
    padding: 32px;
    transition: all 1s ease-in-out;
    max-width: 100%;
    flex-direction: column;

    @media only screen and (max-width: $break-medium) {
        padding: 40px;
    }
}

//NAVBUTTONS
//______________________________________________

.gw-nav-btn {
    background: #fff;
    border: 1px solid $mediumGrey;
    padding: 16px;
    transition: all 0.15s ease-in;

    img {
        display: block;
    }

    &:first-child {
        border-right: none;
    }

    @media (hover: hover) {
        &:hover {
            background: $mediumAmber;
        }
    }
}

.gw-nav-buttons {
    display: flex;
}

//GRIDHELPERS
//______________________________________________

.reverse {
    flex-direction: row-reverse;
}

//WINECAROUSEL
//______________________________________________

.gw-wine-carousel-outer-wrapper {
    border-bottom: 1px solid $mediumGrey;
}

.gw-wine-carousel-inner-wrapper {
    margin: 0 -16px;
}

.gw-wine-carousel-title {
    text-align: center;
}

.gw-wine-carousel-description {
    text-align: center;
    margin: 0 15%;
    margin-bottom: 64px;

    @media only screen and (max-width: $break-medium) {
        margin: 0;
        margin-bottom: 32px;
    }
}

.gw-wine-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $mediumGrey;
    padding: 40px;
    overflow: hidden;
    background: $lightGrey;
    cursor: pointer;
    margin: 0 16px;
    transition: all 0.25s ease-in;

    @media (hover: hover) {
        &:hover {
            background: $mediumAmber;
        }
    }
}

.gw-wine-carousel-item-image {
    height: 220px;
    user-select: none;
}

.gw-wine-carousel-arrows-wrapper {
    display: flex;
}

.gw-wine-carousel-arrows {
    margin-left: auto;
    margin-right: 16px;
    margin-top: 32px;
}

//ABOUTUS
//______________________________________________

.gw-about-us-wrapper {
    border-bottom: 1px solid $mediumGrey;
}

.gw-about-us-image-wrapper {
    border: 1px solid $mediumGrey;
    padding: 16px;

    @media only screen and (max-width: $break-medium) {
        padding: 8px;
    }
}

.gw-about-us-image {
    width: 100%;
    height: auto;
    display: block;
}

.gw-about-us-text {
    @media only screen and (max-width: $break-medium) {
        margin-bottom: 32px;
    }
}

//CONTACT
//______________________________________________

.gw-contact-title {
    text-align: center;
}

.gw-contact-description {
    text-align: center;
    margin: 0 15%;
    margin-bottom: 48px;

    @media only screen and (max-width: $break-medium) {
        margin: 0;
        margin-bottom: 32px;
    }
}

.gw-contact-input-wrapper {
    display: flex;

    margin: 0 15%;

    @media only screen and (max-width: $break-medium) {
        margin: 0;
    }
}

.gw-contact-input {
    width: 100%;
    height: 64px;
    border: 1px solid $mediumGrey;
    font-family: $geoLowerRegular;
    font-size: 1rem;
    padding: 16px;
}

.gw-contact-textarea {
    border: 1px solid $mediumGrey;
    font-family: $geoLowerRegular;
    font-size: 1rem;
    padding: 16px;
    width: 100%;
    resize: vertical;
    height: 200px;
}

.gw-contact-button-wrapper {
    display: flex;
    justify-content: flex-end;
}

//FOOTER
//______________________________________________

.gw-footer-wrapper {
    background-color: #000;
}

.gw-footer-logo {
    width: 165px;
    height: auto;
    display: block;
    margin-bottom: 16px;
}

.gw-footer-description {
    color: $textLightWhite;
}

.gw-footer-nav-title {
    color: $textSecondaryWhite;
    margin-bottom: 16px;
}

.gw-footer-navigation-wrapper {
    display: flex;
    flex-direction: column;
}

.gw-footer-navigation-item {
    cursor: pointer;

    a {
        font-family: $geoCapsRegular;
        font-size: 16px;
        color: $textLightWhite;
        transition: all 0.125s ease-in;
        text-decoration: none;

        @media (hover: hover) {
            &:hover {
                color: #ff8f00;
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.gw-footer-social-wrapper {
    display: flex;
}

.gw-footer-social-item {
    cursor: pointer;
    height: 24px;
    width: 24px;

    &:not(:last-child) {
        margin-right: 16px;
    }
}

.gw-footer-social-item-image {
    display: block;
    height: 100%;
    width: auto;
}

.gw-footer-info {
    @media only screen and (max-width: $break-xl) {
        margin-bottom: 32px;
    }
}

//WINEDETAILED
//______________________________________________

.gw-wine-detailed-wrapper {
    border-bottom: 1px solid $mediumGrey;
}

.gw-nutrition-item {
    display: flex;
    margin-bottom: 4px;
}

.gw-nutrition-item-title {
    white-space: nowrap;

    @media only screen and (max-width: $break-xs) {
        font-size: 14px;
    }
}

.gw-nutrition-item-value {
    margin-left: auto;
    text-align: right;
    white-space: nowrap;

    @media only screen and (max-width: $break-xs) {
        font-size: 14px;
    }

    @media only screen and (max-width: $break-medium) {
        white-space: normal;
    }
}

.gw-nutrition-item-hr {
    width: 100%;
    background: none;
    font-family: $geoLowerRegular;
    font-weight: 500;
    overflow: hidden;
    margin: 0px 8px;
    padding-bottom: 6px;

    &:before {
        content: '.............................................................................................';
        letter-spacing: 4px;
        overflow: hidden;
        color: #cbd5e0;
        font-size: 16px;
    }
}

.gw-wine-detailed-header {
    margin-bottom: 64px;

    @media only screen and (max-width: $break-medium) {
        margin-bottom: 48px;
    }
}

.gw-wine-detailed-nutrition-val {
    margin-bottom: 64px;

    @media only screen and (max-width: $break-medium) {
        margin-bottom: 48px;
    }
}

.gw-wine-detailed-medals-wrapper {
    margin-bottom: 64px;

    @media only screen and (max-width: $break-medium) {
        margin-bottom: 48px;
    }
}

.gw-wine-detailed-pairings-wrapper-outer {
    margin-bottom: 64px;

    @media only screen and (max-width: $break-medium) {
        margin-bottom: 48px;
    }
}

.gw-wine-detailed-pairing-item {
    height: 96px;
    width: auto;
    border: 1px solid $mediumGrey;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    margin: 16px;

    &:last-child {
        margin-right: 32px;
    }
}

.gw-wine-detailed-pairing-image {
    display: block;
    height: 100%;
    width: auto;
}

.gw-wine-detailed-title {
    line-height: 0.8;
    margin-bottom: 16px;
}

.gw-wine-detailed-pairings-wrapper {
    display: flex;
    -ms-overflow-style: none;
    flex-wrap: wrap;
    margin: -16px;

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: $break-small) {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
}

.gw-wine-detailed-image-wrapper {
    @media only screen and (max-width: $break-medium) {
        margin-bottom: 48px;
    }
}

.gw-wine-detailed-map-wrapper {
    height: 300px;
    border: 1px solid $mediumGrey;
    margin-bottom: 16px;
}

.gw-wine-detailed-location {
    border: 1px solid $mediumGrey;
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.125s ease-in;

    @media (hover: hover) {
        &:hover {
            background-color: $mediumAmber;
        }
    }
}

//GALLERY
//______________________________________________

.gw-gallery-wrapper {
    border-bottom: 1px solid $mediumGrey;
}

.gw-gallery-image {
    width: 100%;
    height: auto;
    display: block;
}

.my-masonry-grid {
    display: flex;
    margin-left: -16px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 16px;
    background-clip: padding-box;
}

.gw-gallery-image-wrapper {
    margin-bottom: 16px;
}

.gw-gallery-image {
    cursor: pointer;
}

//IMAGEWITHZOOM
//______________________________________________

.gw-image-zoom-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    padding: 128px;

    @media only screen and (max-width: $break-medium) {
        padding: 64px 16px;
    }
}

.gw-image-zoom-img {
    display: block;
    max-height: 100%;
    width: auto;
    max-width: 100%;
}

.gw-image-zoom-close-btn {
    position: absolute;
    top: 32px;
    right: 32px;
    color: #fff;
    cursor: pointer;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 18px;
}

//WINEBROWSER
//______________________________________________

.gw-wine-browser-wrapper {
    margin-bottom: 24px;
}

.gw-wine-browser-sidebar-wrapper {
    border-left: 1px solid $mediumGrey;
    border-right: 1px solid $mediumGrey;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: $break-medium) {
        border: none;
    }
}

.gw-wine-browser-name-input {
    padding: 12px;
    font-family: $geoLowerRegular;
    font-size: 1rem;
    border: 1px solid $mediumGrey;
    width: 100%;
}

.gw-wine-browser-sidebar-toggle {
    display: none;

    @media only screen and (max-width: $break-medium) {
        padding: 16px;
        margin: 0 -16px;
        display: flex;
        border-bottom: 1px solid $mediumGrey;
        align-items: center;
    }
}

.gw-wine-browser-sidebar-toggle-text {
    margin-left: auto;
    line-height: 0;
}

.gw-wine-browser-sidebar-toggle-icon {
    transition: all 200ms;
}

//WINELIST
//______________________________________________

.gw-wine-list-wrapper {
    padding-left: 64px;

    @media only screen and (max-width: $break-medium) {
        padding-left: 0;
    }
}

.gw-wine-list-title {
    margin-bottom: 32px;
}

//WINEITEM
//______________________________________________

.gw-wine-item-image-wrapper {
    border: 1px solid $mediumGrey;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    transition: all 0.125s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            background-color: $mediumAmber;
        }
    }
}

.gw-wine-item-image {
    height: 220px;
    width: auto;
}

.gw-wine-item-name {
    margin-bottom: -4px;

    a {
        text-decoration: none;
        color: inherit;

        &:visited {
            color: inherit;
        }

        @media (hover: hover) {
            &:hover {
                color: #ff8f00;
            }
        }
    }
}

//SidebarItemCollapsible
//______________________________________________

.gw-sidebar-item-collapsible-header {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
}

.gw-sidebar-item-collapsible-title {
    margin-bottom: -4px;
    margin-right: 8px;
}

.gw-sidebar-item-collapsible-chevron {
    height: 16px;
    width: auto;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.125s ease-in;
}

.gw-sidebar-item-collapsible {
    border-bottom: 1px solid $mediumGrey;
    padding: 32px;

    @media only screen and (max-width: $break-medium) {
        padding: 16px;
        margin: 0 -16px;
    }
}

.gw-sidebar-item-collapsible-child {
    margin-top: 16px;
}

//CheckboxSelect
//______________________________________________

.gw-checkbox-select-option-wrapper {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.gw-checkbox {
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-family: $geoLowerRegular;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        display: inline-block;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 8px;
        display: inline-block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: white;
        border: 1px solid $mediumGrey;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        position: absolute;
        content: '';
        left: 6.5px;
        top: 2.5px;
        width: 3px;
        height: 8px;
        border: solid $textPrimary;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

//WineColorSelect
//______________________________________________

.gw-wine-color-select-wrapper {
    flex-wrap: wrap;
    display: flex;
    margin: -7px;
}

.gw-wine-color-item {
    height: 40px;
    width: 40px;
    background-clip: content-box;
    cursor: pointer;
    transition: all 0.05s ease-in;
    margin: 7px;

    &.selected {
        border: 1px solid $mediumGrey;
        padding: 8px;
    }
}

//Loading
//______________________________________________

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $mediumAmber;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

.gw-loading-section {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.border {
        border-bottom: 1px solid $mediumGrey;
    }
}

.gw-splash-screen {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

//NotFoundPage
//______________________________________________

.gw-404-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

//NOTIFICATION
//______________________________________________

.gw-notification {
    color: #fff;
    padding: 8px 24px;
    padding-right: 16px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 0 8px;

    &.success {
        background-color: #7cb342;
    }

    &.fail {
        background-color: #e53935;
    }

    p {
        margin-right: 24px;
    }

    svg {
        width: 24px;
        height: 24px;
    }
}

//Language Styles
//______________________________________________

.en,
.ru {
    .gw-navbar-item,
    .gw-button,
    h6,
    p,
    h5,
    .gw-contact-input,
    .gw-footer-navigation-item a,
    .gw-nutrition-item-hr,
    .gw-wine-browser-name-input {
        font-family: $enRegular;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: $enBold;
    }

    .gw-checkbox {
        & + label {
            font-family: $enRegular;
        }
    }
}
